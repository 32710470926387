import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { PaymentMethodType } from '@redooo/shared/dist/types/order/paymentMethodType';

export type SelectedPaymentType = PaymentMethodType | null;

@Module({
  name: 'Payment',
  stateFactory: true,
  namespaced: true,
  preserveState: false,
})
export default class PaymentModule extends VuexModule {
  private _selectedPaymentType: SelectedPaymentType = null;
  private _iban = '';
  private _bic = '';
  private _cardHolder = '';
  private _countryCode = 'DE';
  private _pseudoCardpan = '';

  get pseudoCardpan(): string {
    return this._pseudoCardpan;
  }

  @Mutation
  public updatePseudoCardpan(value: string) {
    this._pseudoCardpan = value;
  }

  get iban(): string {
    return this._iban;
  }

  @Mutation
  public updateIban(value: string) {
    this._iban = value;
  }

  get bic(): string {
    return this._bic;
  }

  @Mutation
  public updateBic(value: string) {
    this._bic = value;
  }

  get cardHolder(): string {
    return this._cardHolder;
  }

  @Mutation
  public updateCardholder(value: string) {
    this._cardHolder = value;
  }

  get countryCode(): string {
    return this._countryCode;
  }

  @Mutation
  public updateCountryCode(value: string) {
    this._countryCode = value;
  }

  public get selectedPaymentType(): SelectedPaymentType {
    return this._selectedPaymentType;
  }

  @Mutation
  public updateSelectedPaymentType(selectedPaymentType: SelectedPaymentType) {
    this._selectedPaymentType = selectedPaymentType;
  }

  @Mutation
  public resetSelectedPaymentType() {
    this._selectedPaymentType = null;
  }

  @Mutation
  public resetAll() {
    this._selectedPaymentType = null;
    this._iban = '';
    this._bic = '';
    this._cardHolder = '';
    this._countryCode = 'DE';
    this._pseudoCardpan = '';
  }
}
