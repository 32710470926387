import Vue from 'vue';

const BP_SMALL = 480;
const BP_MEDIUM = 768;
const BP_LARGE = 1024;
const BP_XLARGE = 1440;
const BP_XXLARGE = 1920;

Vue.prototype.$screenWidth = window.innerWidth;
Vue.prototype.$screenIsMobile = window.innerWidth < BP_MEDIUM;
Vue.prototype.$breakpoints = {
  default: 0,
  small: BP_SMALL,
  medium: BP_MEDIUM,
  large: BP_LARGE,
  xlarge: BP_XLARGE,
  xxlarge: BP_XXLARGE,
};
