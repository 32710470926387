import { Route } from 'vue-router';
import { Navigation } from '@redooo/shared/dist/types/content/navigation';
import { CustomerGroup } from '@redooo/shared/dist/types/content/customerGroup';

export const getCustomerSectionFromRoute = (route: Route): Array<string> | undefined => {
  if (route.params?.pathMatch) {
    const pathMatch = route.params.pathMatch;
    const splitPath = pathMatch.split('/');
    splitPath.forEach((name, index) => (splitPath[index] = `/${name}`));
    return splitPath;
  }
};

export const shouldBreadcrumbBeDisplayed = (route: Route): boolean => {
  return (getCustomerSectionFromRoute(route) ?? []).length > 2;
};

export const getNavigationObjectByCustomerGroup = (navObject: Navigation, customerGroup: CustomerGroup): Navigation | undefined => {
  let result;
  const retrievedNavObject = getNavigationObjectByKeyValuePair(navObject, 'customerGroup', customerGroup);
  if (retrievedNavObject) {
    result = retrievedNavObject;
  }
  return result;
};

export const getNavigationObjectByKeyValuePair = (
  navObject: Navigation | Navigation[],
  key: keyof Navigation,
  value: string
): Navigation | undefined => {
  let result;

  if (Array.isArray(navObject)) {
    for (const item of navObject) {
      result = getNavigationObjectByKeyValuePair(item, key, value);
      if (result) {
        break;
      }
    }
  } else {
    let prop: keyof typeof navObject;

    for (prop in navObject) {
      if (prop === key) {
        if (navObject[prop] === value) {
          return navObject;
        }
      }
      if (navObject[prop] instanceof Object || Array.isArray(navObject[prop])) {
        result = getNavigationObjectByKeyValuePair(navObject.children, key, value);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
};

export const getActiveCustomerGroup = (route: Route, navObject: Navigation): CustomerGroup => {
  let result = 'none' as CustomerGroup;
  const customerSection = getCustomerSectionFromRoute(route);
  if (customerSection) {
    for (const possibleSlug of customerSection) {
      const retrievedNavigationObject = getNavigationObjectByKeyValuePair(navObject, 'slug', possibleSlug);
      if (retrievedNavigationObject && retrievedNavigationObject.children.length !== 0) {
        result = retrievedNavigationObject.customerGroup as CustomerGroup;
        break;
      }
    }
  }
  return result;
};

export const getLinkForNavigationItem = (navItem: Navigation) => {
  const isExternalLink = navItem.link?.externalLink !== undefined && navItem.link.externalLink.length !== 0;
  const href = isExternalLink ? navItem!.link!.externalLink : navItem.slug;
  return {
    title: navItem?.title ?? 'n/a',
    isInternalLink: !isExternalLink,
    href: href ?? '/',
    target: navItem?.link?.externalTarget,
  };
};

export const getLinkForNavigationItemRefId = (
  navigationRefId: string,
  mainNavigation: Navigation
):
  | undefined
  | {
      title: string;
      isInternalLink: boolean;
      href: string;
      target: string | undefined;
    } => {
  const navItem = getNavigationObjectByKeyValuePair(mainNavigation, 'id', navigationRefId);
  if (!navItem) return undefined;

  return getLinkForNavigationItem(navItem);
};
