import { Context, Middleware } from '@nuxt/types';
import { sharedStore } from '../store';
import { ShopSteps } from '../store/Shared';

const getShopRoutes = (context: Context): string[] => {
  return [
    context.app.localePath({ name: 'shop-step-one' }),
    context.app.localePath({ name: 'shop-step-two' }),
    context.app.localePath({ name: 'shop-step-three' }),
    context.app.localePath({ name: 'shop-step-four' }),
    context.app.localePath({ name: 'shop-step-five' }),
  ];
};

const shopGuard: Middleware = (context) => {
  const shopRoutes = getShopRoutes(context);
  const currentRoute = context.route.path;
  const currentStep = shopRoutes.indexOf(currentRoute) + 1;
  const lastEnteredStep = sharedStore.lastPassedShopStep || 0;

  //* Route has hash from query via payone-redirectErrorPath
  if (context.route.query.hash) return;

  if (currentStep !== 0) {
    if (currentStep > lastEnteredStep + 1) {
      const redirectTo = context.app.localePath('/');
      return context.redirect(redirectTo);
    }
    if (lastEnteredStep >= currentStep) {
      sharedStore.updateLastPassedShopStep((currentStep - 1) as ShopSteps);
    }
  }
};

export default shopGuard;
