import Vue from 'vue';
import { LOCALE_CODE } from '@redooo/shared/dist/types/generated/contentful';
import { Locale } from 'nuxt-i18n';

// input: price in cent, output: formatted price in euros
export function toCurrency(value: number, locale: LOCALE_CODE | Locale) {
  // TODO: get lang code & unit dynamically from current setting (i18n.locale, as argument?) currency from contentful?
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  return formatter.format(value / 100);
}

// input in liter, output: cubic meter
export function toCubicmeter(value: number, locale: LOCALE_CODE | Locale) {
  return (value / 1000).toLocaleString(locale);
}

// input in mm, output: meter
export function toMeter(value: number, locale: LOCALE_CODE | Locale) {
  return (value / 1000).toLocaleString(locale);
}

export function toCreditCardNumber(value: string) {
  const splittedString = value.match(/.{1,4}/g);
  return splittedString?.join(' ');
}

export function toCreditCardExpirationDate(value: string) {
  const splittedString = value.match(/.{1,2}/g);
  return splittedString?.reverse().join('/');
}

export function toDate(value: string) {
  return new Date(value).toLocaleDateString('de-DE');
}

Vue.filter('capitalize', function (value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});
