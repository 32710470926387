import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import PaymentModule from '../store/Payment';
import SharedModule from '../store/Shared';
import ShopStoreModule from '../store/Shop';
import SiteConfigurationModule from '../store/SiteConfiguration';

let paymentStore: PaymentModule;
let shopStore: ShopStoreModule;
let siteConfigStore: SiteConfigurationModule;
let sharedStore: SharedModule;

function initialiseStores(store: Store<any>): void {
  paymentStore = getModule(PaymentModule, store);
  shopStore = getModule(ShopStoreModule, store);
  siteConfigStore = getModule(SiteConfigurationModule, store);
  sharedStore = getModule(SharedModule, store);
}

export { initialiseStores, paymentStore, shopStore, siteConfigStore, sharedStore };
