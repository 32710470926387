import { SiteConfiguration } from '@redooo/shared/dist/types/content/siteConfiguration';
import { CookieConsent } from '@redooo/shared/dist/types/content/cookieConsent';
import { getMasterSiteConfiguration, getLocalizedSiteConfigurations } from '@redooo/shared/dist/helpers/content';
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

export type UserType = 'privatkunden' | 'geschaeftskunden' | 'partner';
export type UserTypeEN = 'private-customers' | 'commercial-customers' | 'partner';

const getUserTypeFromRoutePath = (str: string): UserType | UserTypeEN => {
  if (str.split(/[/]/)[1] === 'en') {
    return str.split(/[/]/)[2] as UserTypeEN;
  }
  return str.split(/[/]/)[1] as UserType;
};

@Module({
  name: 'SiteConfiguration',
  stateFactory: true,
  namespaced: true,
})
export default class SiteConfigurationModule extends VuexModule {
  private _configurations: SiteConfiguration[] = [];
  private _prevRoutePath: string = '';
  private _routerFromRoutePath: string = '';
  private _routerToRoutePath: string = '';

  public get configurations(): SiteConfiguration[] {
    return this._configurations;
  }

  public get masterConfiguration(): SiteConfiguration {
    return getMasterSiteConfiguration(this._configurations);
  }

  public get cookieConsent(): CookieConsent | undefined {
    return this.masterConfiguration.cookieConsent;
  }

  public get localizedConfigurations(): SiteConfiguration[] {
    return getLocalizedSiteConfigurations(this._configurations);
  }

  public get getPreviousRoutePath(): string {
    return this._prevRoutePath;
  }

  public get getCurrentUserType(): UserType | UserTypeEN {
    return getUserTypeFromRoutePath(this._prevRoutePath);
  }

  public get getRouterFromRoutePath(): string {
    return this._routerFromRoutePath;
  }

  public get getRouterToRoutePath(): string {
    return this._routerToRoutePath;
  }

  @Mutation
  public setConfiguration(siteConfiguration: SiteConfiguration[]) {
    this._configurations = siteConfiguration;
  }

  @Mutation
  public setPrevRoutePath(prevRoutePath: string) {
    this._prevRoutePath = prevRoutePath;
  }

  @Mutation
  public setRouterPath(payload: { routerPath: string; type: 'from' | 'to' }): void {
    if (payload.type === 'from') this._routerFromRoutePath = payload.routerPath;
    else this._routerToRoutePath = payload.routerPath;
  }
}
