import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { CustomerWasteTypeContainerPrice } from '@redooo/shared/dist/types/services/customerWasteTypeContainerPrice';
import { WasteType } from '@redooo/shared/dist/types/services/wasteType';
import { OrderItem } from '@redooo/shared/dist/types/ui/orderItem';
import Location from '@redooo/shared/dist/types/ui/location';
import { OrderFormData } from '@redooo/shared/dist/types/ui/orderFormData';
import type { PartialDeep } from 'type-fest';
@Module({
  name: 'Shop',
  stateFactory: true,
  namespaced: true,
})
export default class ShopModule extends VuexModule {
  private _requestedLocale = '';

  private _selectedLocation: Location = {
    timestamp: 0,
    zipCode: '',
    state: '',
  };

  private _httpApiLoadingErrorOccurred = false;

  private _selectedWasteTypeId = '';

  private _selectedContainerTypeId = '';

  private _customerWasteTypeContainerPrices: CustomerWasteTypeContainerPrice[] = [];

  private _wasteTypeAndDependentContainerTypes: WasteType[] = [];

  private _basketItems: OrderItem[] = [];

  private _orderData: PartialDeep<OrderFormData> = {};

  private _isInvoicePaymentTypeEnabled = false;

  private _isBigBagOrder = false;

  public get isHttpApiLoadingErrorOccurred(): boolean {
    return this._httpApiLoadingErrorOccurred;
  }

  public get selectedLocation(): Location {
    return this._selectedLocation;
  }

  public get requestedLocale(): string {
    return this._requestedLocale;
  }

  public get customerWasteTypeContainerPrices(): CustomerWasteTypeContainerPrice[] {
    return this._customerWasteTypeContainerPrices;
  }

  @Mutation
  public setIsBigBagOrder(isBigBag: boolean) {
    this._isBigBagOrder = isBigBag;
  }

  public get isBigBagOrder(): boolean {
    return this._isBigBagOrder;
  }

  public get selectedWasteTypeId(): string {
    return this._selectedWasteTypeId;
  }

  public get selectedContainerTypeId(): string {
    return this._selectedContainerTypeId;
  }

  public get wasteTypeAndDependentContainerTypes(): WasteType[] {
    return this._wasteTypeAndDependentContainerTypes;
  }

  public get basketItems() {
    return this._basketItems;
  }

  public get orderData(): PartialDeep<OrderFormData> {
    return this._orderData;
  }

  public get currentZipCode(): string {
    return this._selectedLocation?.zipCode;
  }

  @Mutation
  public updateHttpApiLoadingErrorOccurred(success: boolean) {
    this._httpApiLoadingErrorOccurred = success;
  }

  @Mutation
  public updateSelectedLocation(location: Location) {
    this._selectedLocation = location;
  }

  @Mutation
  public updateRequestedLocale(locale: string) {
    this._requestedLocale = locale;
  }

  @Mutation
  public updateSelectedWasteTypeId(wasteTypeId: string) {
    this._selectedWasteTypeId = wasteTypeId;
  }

  @Mutation
  public updateSelectedContainerTypeId(containerTypeId: string) {
    this._selectedContainerTypeId = containerTypeId;
  }

  @Mutation
  public updateWasteTypeAndDependentContainerTypes(wasteTypes: WasteType[]) {
    this._wasteTypeAndDependentContainerTypes = wasteTypes;
  }

  @Mutation
  public clearBasket() {
    this._basketItems = [];
  }

  @Mutation
  public updateBasketItem(item: OrderItem) {
    const newBasketItems = this._basketItems.slice();
    const indexToBeUpdated = this._basketItems.findIndex((x) => x.id === item.id);
    if (indexToBeUpdated !== -1) {
      newBasketItems[indexToBeUpdated] = item;
    }
    this._basketItems = newBasketItems;
  }

  @Mutation
  public addToBasket(item: OrderItem) {
    // for now as only one item is allowed - kill the others before creating a new one
    this._basketItems = [];
    const newBasketItems = this._basketItems.slice();
    newBasketItems.push(item);
    this._basketItems = newBasketItems;
  }

  @Mutation
  public removeFromBasket(itemId: string) {
    const indexOfItemToBeRemoved = this._basketItems.findIndex((x) => x.id === itemId);
    const newBasketItems = this._basketItems;
    newBasketItems.splice(indexOfItemToBeRemoved, 1);
    this._basketItems = newBasketItems;
  }

  @Mutation
  public updateOrderData(orderData: PartialDeep<OrderFormData>) {
    this._orderData = orderData;
  }

  @Mutation
  public clearOrderData() {
    this._orderData = {};
  }

  @Mutation
  public updateCustomerWasteTypeContainerPrices(prices: CustomerWasteTypeContainerPrice[]) {
    this._customerWasteTypeContainerPrices = prices;
  }

  public get isInvoicePaymentTypeEnabled(): boolean {
    return this._isInvoicePaymentTypeEnabled;
  }

  @Mutation
  public updateisInvoicePaymentTypeEnabled(value: boolean) {
    this._isInvoicePaymentTypeEnabled = value;
  }

  @Mutation
  public resetAll() {
    this._orderData = {};
    this._basketItems = [];
    this._customerWasteTypeContainerPrices = [];
    this._wasteTypeAndDependentContainerTypes = [];
    this._selectedWasteTypeId = '';
    this._selectedContainerTypeId = '';
    this._isInvoicePaymentTypeEnabled = false;
    this._isBigBagOrder = false;
    this._selectedLocation = {
      timestamp: 0,
      zipCode: '',
      state: '',
    };
  }
}
