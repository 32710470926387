import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
export type ShopSteps = -1 | 0 | 1 | 2 | 3 | 4 | 5;

export type CookieConsent = {
  showModal: boolean;
  necessary: boolean;
  statistic: boolean;
};
@Module({
  name: 'Shared',
  stateFactory: true,
  namespaced: true,
})
export default class CookieModule extends VuexModule {
  private _lastPassedShopStep: ShopSteps = -1;

  private _cookieConsent: CookieConsent = { showModal: true, necessary: true, statistic: false };

  public get lastPassedShopStep(): ShopSteps {
    return this._lastPassedShopStep;
  }

  @Mutation
  public updateLastPassedShopStep(step: ShopSteps) {
    this._lastPassedShopStep = step;
  }

  public get cookieConsent(): CookieConsent {
    return this._cookieConsent;
  }

  @Mutation
  public updateCookieConsent(payload: CookieConsent) {
    this._cookieConsent = payload;
  }
}
