import { Middleware } from '@nuxt/types';

const FALLBACK_PATH = '/privatkunden';
const FALLBACK_PATH_EN = '/en/private-customers';

const isLocalRoute = (routeName: string): boolean => {
  const localRoutesNames = ['contact', 'shop'];
  return (
    localRoutesNames.filter((localRouteName) => {
      return routeName.match(`${localRouteName}`);
    }).length > 0
  );
};

const route: Middleware = ({ route, store }) => {
  if (route.name) {
    const hasPrevRoutePath = store.state.SiteConfiguration._prevRoutePath;
    if (!isLocalRoute(route.name)) store.commit('SiteConfiguration/setPrevRoutePath', route.path);
    else if (!hasPrevRoutePath) {
      if (route.name.includes('en')) store.commit('SiteConfiguration/setPrevRoutePath', FALLBACK_PATH_EN);
      else store.commit('SiteConfiguration/setPrevRoutePath', FALLBACK_PATH);
    }
  }
};

export default route;
