import { SiteConfiguration } from '@redooo/shared/dist/types/content/siteConfiguration';
import { Page } from '@redooo/shared/dist/types/content/page';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { plainToClass } from 'class-transformer';
import { shopStore } from '../../../utils/store-accessor';

const getSiteConfiguration = async ($sentryLogger: any, $axios: NuxtAxiosInstance): Promise<SiteConfiguration[]> => {
  let response: any;
  const siteConfigEntryId = '6OKnIf5po5PPDfxZ4TDqna';
  const siteConfigurations: SiteConfiguration[] = [];

  try {
    response = await $axios.$get(`/content/siteConfiguration/${siteConfigEntryId}`);
    if (response && response.data && Array.isArray(response.data)) {
      response.data.forEach((siteConfigurationResponse: any) => {
        const siteConfiguration = plainToClass(SiteConfiguration, siteConfigurationResponse as object);
        if (siteConfiguration) siteConfigurations.push(siteConfiguration);
      });
    }
  } catch (error) {
    $sentryLogger('Error getting siteconfiguration from service', { originError: error, siteConfigurationResponse: response });
  }

  return siteConfigurations;
};

const getPage = async ($sentryLogger: any, $axios: NuxtAxiosInstance, pageId: string, locale: string): Promise<Page | undefined> => {
  let response: any;
  try {
    if (shopStore.isHttpApiLoadingErrorOccurred) shopStore.updateHttpApiLoadingErrorOccurred(false);
    response = await $axios.$get(`/content/page/${pageId}/${locale}`);
  } catch (error) {
    $sentryLogger('Error getting page from service', { originError: error, getPageResponse: response });
  }

  if (response?.data) {
    return plainToClass(Page, response.data as object);
  } else {
    $sentryLogger('response.data not set in getPageResponse', { response });
    return undefined;
  }
};

const getPagePreview = async ($sentryLogger: any, $axios: NuxtAxiosInstance, pageId: string, locale: string): Promise<Page | undefined> => {
  let response: any;
  try {
    if (shopStore.isHttpApiLoadingErrorOccurred) shopStore.updateHttpApiLoadingErrorOccurred(false);
    response = await $axios.$get(`/content/page_preview/${pageId}/${locale}`);
  } catch (error) {
    $sentryLogger('Error getting page from service', { originError: error, getPageResponse: response });
  }

  if (response?.data) {
    return plainToClass(Page, response.data as object);
  } else {
    $sentryLogger('response.data not set in getPageResponse', { response });
    return undefined;
  }
};

export { getPage, getPagePreview, getSiteConfiguration };
