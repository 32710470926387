import VuexPersistence from 'vuex-persist';
import Cookie from 'cookie-universal';

export default ({ store, req, res }: any) => {
  const cookieExpirationDate = new Date();
  cookieExpirationDate.setMonth(cookieExpirationDate.getMonth() + 6);

  // TODO set domain *.redooo.de?

  new VuexPersistence({
    key: 'redooo_shared',
    restoreState: (key) => {
      const cookieVal = Cookie(req, res);
      // logger.debug('restore cookie state on server?', req !== null, cookieVal.get(key));
      return cookieVal.get(key);
    },
    saveState: (key, state) => {
      // logger.debug('save cookie state', key, state);
      return Cookie().set(key, state, { expires: cookieExpirationDate, path: '/', secure: true });
    },
    modules: ['Shared'],
  }).plugin(store);
};
