import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import logger from '@redooo/shared/dist/services/logging';
import { Severity } from '@sentry/types/types/severity';

export default (context: Context, inject: Inject) => {
  const sentryLogger = (
    transactionName: string,
    customData?: Record<string, unknown>,
    tags?: { key: string; value: string }[],
    level: string = 'error'
  ) => {
    // FIXME: exec additional a console.log?
    logger.error(transactionName, level);
    context.$sentry.withScope((scope) => {
      scope.setLevel(level as Severity);
      if (tags) {
        tags.forEach((tag) => {
          scope.setTag(tag.key, tag.value);
        });
      }
      customData && scope.setContext('RDO-CUSTOMDATA', customData);
      context.$sentry.captureMessage(transactionName);
    });
  };

  inject('sentryLogger', sentryLogger);
};
