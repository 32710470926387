import { Context } from '@nuxt/types';
import { Store } from 'vuex';
import logger from '@redooo/shared/dist/services/logging';
import { getSiteConfiguration } from '../services/api/content';
import { initialiseStores, paymentStore, siteConfigStore, shopStore, sharedStore } from '~/utils/store-accessor';

const initializer = (store: Store<any>) => initialiseStores(store);
export const plugins = [initializer];

export const actions = {
  async nuxtServerInit(_storeCtx: Context, ctx: Context) {
    if (ctx.$config.isDevMode) logger.log('triggering store initialization ...', ctx.$config);
    const siteConfig = await getSiteConfiguration(ctx.$sentryLogger, ctx.$axios);
    if (!siteConfig || siteConfig.length === 0) {
      // sentrylogger catched in getSiteConfiguration()
      // ctx.$sentryLogger('invalid site configuration', { siteConfig });
      // throw new Error('invalid site configuration');
      ctx.error({ message: 'notAvailable' });
    } else {
      siteConfigStore.setConfiguration(siteConfig);
    }
  },
};

export { paymentStore, siteConfigStore, shopStore, sharedStore };
