import { extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { alpha, alpha_num, email, max, min, required } from 'vee-validate/dist/rules';
import { Context } from '@nuxt/types';
import { isPostalCode, isIBAN, isBIC } from 'class-validator';

export default function configureVeeValidate(context: Context) {
  setVeeValidateRules(context);

  context.app.i18n.onLanguageSwitched = () => {
    setVeeValidateRules(context);
  };
}

function setVeeValidateRules(context: Context) {
  extend('alpha', {
    ...alpha,
    message: context.app.i18n.t('global.forms.errorMessages.alpha') as string,
  });

  extend('alpha_num', {
    // eslint-disable-next-line camelcase
    ...alpha_num,
    message: context.app.i18n.t('global.forms.errorMessages.alpha_num') as string,
  });

  extend('alpha_1', {
    // eslint-disable-next-line camelcase
    validate: (value: string) => {
      // Accept: a-z, A-Z, äÄöÖüÜß, including whitespace and - (minus)
      const regexp = /^[\sA-Za-z-\u00E4\u00C4\u00F6\u00D6\u00FC\u00DC\u00DF]+$/;
      return regexp.test(value);
    },
    message: 'Es sind nur Buchstaben, Umlaute, Leerzeichen und das folgende Sonderzeichen: - (Minus) erlaubt.',
  });

  extend('alpha_2', {
    // eslint-disable-next-line camelcase
    validate: (value: string) => {
      // Accept: a-z, A-Z, äÄöÖüÜß, including whitespace, - (minus), . (dot)
      const regexp = /^[\sA-Za-z-.\u00E4\u00C4\u00F6\u00D6\u00FC\u00DC\u00DF]+$/;
      return regexp.test(value);
    },
    message: 'Es sind nur Buchstaben, Umlaute, Leerzeichen und die folgenden Sonderzeichen: - (Minus) und . (Punkt) erlaubt.',
  });

  extend('bic', {
    validate: (value) => {
      return isBIC(value);
    },
    message: context.app.i18n.t('global.forms.errorMessages.bic') as string,
  });

  extend('email', {
    ...email,
    message: context.app.i18n.t('global.forms.errorMessages.email') as string,
  });

  extend('iban', {
    validate: (value) => {
      return isIBAN(value);
    },
    message: context.app.i18n.t('global.forms.errorMessages.iban') as string,
  });

  extend('min', {
    ...min,
    message: context.app.i18n.t('global.forms.errorMessages.min') as string,
  });

  extend('max', {
    ...max,
    message: context.app.i18n.t('global.forms.errorMessages.max') as string,
  });

  extend('phone', {
    validate: (value: string) => {
      // Accept: 0-9 including whitespace, - (minus), + (plus),
      const regexp = /^[\s0-9-+()]+$/;
      return regexp.test(value);
    },
    message: context.app.i18n.t('global.forms.errorMessages.phone') as string,
  });

  extend('required', {
    ...required,
    message: context.app.i18n.t('global.forms.errorMessages.required') as string,
  });

  extend('zipCode', {
    validate: (value) => {
      return isPostalCode(value, 'DE');
    },
    message: context.app.i18n.t('global.forms.errorMessages.zipCode') as string,
  });
}
