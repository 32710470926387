import { Middleware } from '@nuxt/types';

const maintenance: Middleware = ({ redirect, $config, route }) => {
  // Redirect to maintenance-page
  if ($config.isMaintenance) {
    if (!route.name?.includes('maintenance')) redirect('/wartungsarbeiten');
  }
};

export default maintenance;
