import { Middleware } from '@nuxt/types';
import logger from '@redooo/shared/dist/services/logging';

const affiliateTracking: Middleware = (context) => {
  if (!context.route.query) return;

  const referrerId = context.route.query[context.$config.referrerQueryStringKey];
  if (referrerId) {
    logger.info('affiliateTracking detected, referrerId cookie is set to', referrerId);
    context.$cookies.set(context.$config.referrerQueryStringKey, referrerId, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30, // 30 days
    });
  }
};

export default affiliateTracking;
