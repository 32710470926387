// @ts-nocheck
import { CookieConsent } from '../store/Shared';
import { sharedStore } from '../store';

export default ({ store, app, $config }: any) => {
  store.watch(
    () => sharedStore.cookieConsent,
    (cookieConsentNew: CookieConsent, cookieConsentOld: CookieConsent) => {
      if (cookieConsentNew.statistic) {
        // Activate Google-Tag-Manager
        app.$gtm.init($config.gtmId);

        // Activate Hotjar
        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              // eslint-disable-next-line prefer-rest-params
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: +$config.hotjarSiteId, hjsv: 6 };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script');
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      }

      if (cookieConsentNew.statistic !== cookieConsentOld.statistic && !cookieConsentNew.statistic) {
        window.location.reload();
      }
    }
  );
};
